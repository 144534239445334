<template>
  <div>
    <div>
      <a-card class="card" title="" :bordered="false">
        <a-form-model :rules="rules" :form="details" ref="ruleForm" :model="details" @ok="handleOk">
          <a-row class="form-row">
            <a-col :lg="6" :md="12" :sm="24">
              <a-form-model-item ref="name" prop="name" label="公司全称">
                <!-- <a-input v-model="details.name" :disabled="!isEdit"/> -->
                <a-select
                  show-search
                    label-in-value
                    :value="searchVal"
                    :disabled="!isEdit"
                    placeholder="请输入公司名称"
                    style="width: 100%"
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    @search="fetchUser"
                    @change="handleGsChange"
                  >
                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                    <a-select-option v-for="d in searchData" :key="d.Name" @click="selectGs(d)">
                      {{ d.Name }}
                    </a-select-option>
                  </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
              <a-form-model-item label="公司简称">
                <a-input v-model="details.abbreviation" placeholder="请输入公司简称" :disabled="!isEdit"/>
              </a-form-model-item>
            </a-col>
            <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 10 }" :md="{ span: 24 }" :sm="24">
              <a-form-model-item label="公司人数">
                <a-input v-model="details.scale"  :disabled="!isEdit" placeholder="请输入公司人数" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row class="form-row">
            <a-col :lg="6" :md="12" :sm="24">
              <a-form-model-item label="公司类型">
                <a-select :disabled="!isEdit" v-model="details.enterpriseType" style="width: 100%"
                          placeholder="请选择公司类型">
                  <a-select-option :value="index + 1" :label="item.name" v-for=" (item, index) in enterpriseType" :key="index">
                    {{ item }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
              <a-form-model-item label="来源">
                <a-select :disabled="!isEdit" v-model="details.source" style="width: 100%" placeholder="请选择来源">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in soureList" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 10 }" :md="{ span: 24 }" :sm="24">
              <a-form-model-item label="客户经理" ref="customerManagerIdList" prop="customerManagerIdList">
                <a-select :disabled="!isEdit" v-model="details.customerManagerIdList" mode="multiple"
                          style="width: 100%" placeholder="请选择客户经理">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in users" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row class="form-row" style="display: flex;">
            <a-col :lg="6" :md="12" :sm="24">
              <a-form-model-item label="备注">
                <a-textarea :disabled="!isEdit" v-model="details.remarks"/>
              </a-form-model-item>
            </a-col>
            <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
              <a-form-model-item label="公司地址">
                <div style="min-width: 400px;width: 420px;">
                  <div style="display: flex;margin-bottom: 10px;align-items: center ;"
                       v-for="(it, i) in details.customerAddressList" :key="i">
                    <a-select :disabled="!isEdit" v-if="it.provinceName" v-model="it.provinceName"
                              @change="provChange($event, i)" :index="i"
                              placeholder="省">
                      <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in prov" :key="index">{{
                          item.name
                        }}
                      </a-select-option>
                    </a-select>
                    <a-select :disabled="!isEdit" v-else @change="provChange($event, i)" :index="i" placeholder="省">
                      <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in prov" :key="index">{{
                          item.name
                        }}
                      </a-select-option>
                    </a-select>
                    <a-select :disabled="!isEdit" v-if="it.cityName" v-model="it.cityName"
                              @change="cityChange($event, i)" :index="i"
                              placeholder="市">
                      <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in city" :key="index">{{
                          item.name
                        }}
                      </a-select-option>
                    </a-select>
                    <a-select :disabled="!isEdit" v-else @change="cityChange($event, i)" :index="i" placeholder="市">
                      <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in city" :key="index">{{
                          item.name
                        }}
                      </a-select-option>
                    </a-select>
                    <a-select :disabled="!isEdit" v-if="it.countyName" v-model="it.countyName"
                              @change="areaChange($event, i)" :index="i"
                              placeholder="区">
                      <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in area" :key="index">{{
                          item.name
                        }}
                      </a-select-option>
                    </a-select>
                    <a-select :disabled="!isEdit" v-else @change="areaChange($event, i)" :index="i" placeholder="区">
                      <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in area" :key="index">{{
                          item.name
                        }}
                      </a-select-option>
                    </a-select>
                    <a-input :disabled="!isEdit" v-model="it.detailedAddress" placeholder="详细地址"/>
                    <div v-if="isEdit">
                      <a-button v-if="i == 0" type="primary" style="margin-left: 10px;"
                                @click="addAddress(3, i)">添加
                      </a-button>
                      <a-button v-else style="margin-left: 10px;" @click="addAddress(4, i)">删除</a-button>
                    </div>
                  </div>
                </div>
              </a-form-model-item>
         
            </a-col>
            <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 10 }" :md="{ span: 24 }" :sm="24">
          
            </a-col>
          </a-row>

          <a-row>
            <a-form-model-item required ref="customerWelfareList" prop="customerWelfareList">
                <p style="display:flex;align-items: center;font-size:14px;">
                  <span style="color:#f5222d;">*&nbsp;</span>
                  预算总额：¥{{ totalWelfareAmount }}
                  <a-tooltip placement="topLeft" :title="describeText">
                    <a-icon type="question-circle" style="color:#f5222d;font-size: 16px;margin-left: 8px;"/>
                  </a-tooltip>
                </p>
                <ul style="position: relative;list-style: none;padding:0;flex-wrap: wrap;">
                  <li v-for="(item,index) in details.customerWelfareList" :key="item.id" style="display: flex;margin-right: 20px;">
                    <p style="display: flex;align-items: center;margin-right: 0px;">
                      <span v-if="item.welfareName">{{ item.welfareName }}:</span>
                      <span v-else>{{ item.describe }}</span>
                      <a-input :disabled="!isEdit" style="width:80px;margin: 0 0px 0 10px;" v-model="item.welfareAmount"/>
                      <span style="margin: 0 10px;">元/年</span>
                    </p>
                    <p>
                      <span>预计采购月份 : </span>
                      <a-select style="width: 60px;" :disabled="!isEdit" v-model="item.expectedProcurementMonth" placeholder="月份">
                        <a-select-option :value="it" :label="it" v-for="it in monthDate" :key="it">
                          {{it}}
                        </a-select-option>
                      </a-select>
                    月
                    </p>
                    <a-button style="margin-left: 10px;" type="primary" @click="addWelfare(item)" v-if="!item.isDel && isEdit">添加</a-button>
                    <a-button style="margin-left: 10px;" @click="delWelfare(index)" v-if="item.isDel && isEdit">删除</a-button>
                  
                  </li>
                </ul>
              </a-form-model-item>
          </a-row>
        

        </a-form-model>
      </a-card>
      <a-card title="联系人信息" :bordered="false">
        <sfaContactsList :isEdit="isEdit" :selectId="selectId" :sfaContactsList="details.sfaContactsList" :isShowAddress="isShowEdit" :isBackShow="false"></sfaContactsList>
      </a-card>

      <a-card title="发票" :bordered="false">
        <InvoiceList :isEdit="isEdit" :invoiceData="invoiceData" :selectId="selectId" :isBackShow="false"></InvoiceList>
      </a-card>
    </div>


    <footer-tool-bar v-if="isShowEdit">
      <a-button v-if="isEdit" type="primary" @click="handleOk" :loading="loading">{{ $t('提交') }}</a-button>
      <a-button v-if="!isEdit" type="primary" @click="isEdit = !isEdit" :loading="loading">{{ $t('编辑') }}</a-button>
    </footer-tool-bar>
    <div style="height: 40px;"></div>
  </div>
</template>

<script>
import {request} from '@/utils/request'
import FooterToolBar from '@/components/tool/FooterToolBar'
import sfaContactsList from '@/pages/sfa_customer/Contact'
import InvoiceList from '@/pages/sfa_customer/InvoiceList'

const columns = [
  {
    title: '联系人',
    dataIndex: 'name',
    scopedSlots: {customRender: 'name'},
    align: 'center',
    width: 100,
  },
  {
    title: '联系人角色',
    dataIndex: 'roleName',
    scopedSlots: {customRender: 'roleName'},
    align: 'center',
    width: 100

  },
  {
    title: '手机号',
    dataIndex: 'phone',
    align: 'center',
    width: 100,
    scopedSlots: {customRender: 'phone'},
  },
  {
    title: '座机号',
    dataIndex: 'landline',
    align: 'center',
    width: 100,
    scopedSlots: {customRender: 'landline'},
  },
  {
    title: '性别',
    dataIndex: 'sexName',
    align: 'center',
    width: 50
  },
  {
    title: '生日',
    dataIndex: 'birthday',
    scopedSlots: {customRender: 'birthday'},
    align: 'center',
    width: 100,
  },
  {
    title: '邮箱',
    dataIndex: 'mailbox',
    ellipsis: true,
    align: 'center',
    width: 100,
    scopedSlots: {customRender: 'mailbox'},
  },
  {
    title: '微信号',
    dataIndex: 'wechat',
    ellipsis: true,
    align: 'center',
    width: 100,
    scopedSlots: {customRender: 'mailbox'},
  },
  {
    title: 'QQ',
    dataIndex: 'qq',
    ellipsis: true,
    align: 'center',
    width: 100,
    scopedSlots: {customRender: 'mailbox'},
  },
  {
    title: '其他联系方式',
    dataIndex: 'othen',
    ellipsis: true,
    align: 'center',
    width: 100,
    scopedSlots: {customRender: 'mailbox'},
  },
  {
    title: '操作',
    scopedSlots: {customRender: 'action'},
    align: 'center',
    width: 160
  }
]
import debounce from 'lodash/debounce';
import { EventBus } from '@/utils/event-bus.js';
export default {
  name: 'AdvancedForm',
  components: {FooterToolBar, sfaContactsList, InvoiceList},
  props: {
    isBack: {
      type: Boolean,
      default: true
    },
    selectId: {
      type: String,
      default: ""
    },
    isShowEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 900);
    return {
      searchData: [],
      searchVal: {},
      fetching: false,
      loading: false,
      isEdit: false,
      columns,
      form: this.$form.createForm(this),
      details: {
        name: '',
        abbreviation: '',
        scale: '',
        enterpriseType: '',
        source: '',
        customerManagerIdList: [],
        remarks: '',
        customerWelfareList: [],
      },
      dataSource: {},
      welfare: {},
      // 公司人数
      scaleList: [{id: 1, name: '50人以下'}, {id: 2, name: '50-99人'}, {id: 3, name: '100-499人'}, {
        id: 4,
        name: '500-999人'
      }, {id: 5, name: '1000-9999人'}, {id: 6, name: '10000人以上'},],
      // 类型
      enterpriseType: ['医院', '街道', '医院', '综合', '咨询/设备/技术公司', '科技公司', '出版社', '酒店', '工程有限公司', '银行', '事业单位', '路桥建设', '税务局', '卫生局', '审计局', '进出口贸易', '园林局', '学校', '其他'],
      // 来源
      soureList: [{name:'天眼查',id:1},{name:'企查查',id:2}, {name:'陌生拜访',id:3}, {name:'朋友介绍',id:4}, {name:'客户转介绍',id:6},  {name:'其他',id:5}],
      prov: [],
      city: [],
      area: [],
      users: [],
      rules: {
        name: [{required: true, message: '请输入联系人', trigger: 'blur'}],
        gsname: [{required: true, message: '请输入公司全称', trigger: 'blur'}],
        role: [{required: true, message: '请选择角色', trigger: 'change'}],
        customerManagerIdList: [{required: true, message: '请选择客户经理', trigger: 'change'}],
        abbreviation: [{required: true, message: '请输入公司简称', trigger: 'blur'}],
        // scale: [{required: true, message: '请选择公司人数', trigger: 'change'}],
        scale: [{ required: true, message: '请输入公司人数', trigger: 'blur' }],
        enterpriseType: [{required: true, message: '请选择公司类型', trigger: 'change'}],
        source: [{required: true, message: '请选择来源', trigger: 'change'}],
      },
      describeText: "",
      monthDate:["01","02","03","04","05","06","07","08","09","10","11","12"],
       // 福利列表
       defaultWelfareList: [
          {
            "welfareId": "2GBge756Ad",
            "expectedProcurementMonth":"0",
            "welfareAmount": 0
          }, {
            "welfareId": "uMutq2tNYO",
            "expectedProcurementMonth":"0",
            "welfareAmount": 0
          }, {
            "welfareId": "Euw01T1K1T",
            "expectedProcurementMonth":"0",
            "welfareAmount": 0
          }, {
            "welfareId": "KfY1Ei3gQi",
            "expectedProcurementMonth":"0",
            "welfareAmount": 0
          }, {
            "welfareId": "JxBVvK5vJX",
            "expectedProcurementMonth":"0",
            "welfareAmount": 0
          }, {
            "welfareId": "lltjBncXD3",
            "expectedProcurementMonth":"0",
            "welfareAmount": 0
          }, {
            "welfareId": "Bb1qHXRyZi",
            "expectedProcurementMonth":"0",
            "welfareAmount": 0
          }
        ],
        invoiceData:{}
    }
  },
  watch: {
    selectId: {
      immediate: true, // 立即执行一次
      handler() {
        this.getCusDetails()
      }
    }
  },
  computed: {
    totalWelfareAmount() {
      return this.details.customerWelfareList.reduce((total, item) => {
        if (item.welfareAmount) {
          this.details.budgetAmount = total + Number(item.welfareAmount);
        } else {
          item.welfareAmount = 0
        }
        return total + Number(item.welfareAmount);
      }, 0);
    },
  },
  mounted() {
    // 详情
    this.getCusDetails()
    this.getCity()

    //  客户经理 
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/users/listNormal', 'post',{}).then(res => {
      this.users = res.data.data;
    })
  },
  methods: {
    fetchUser(value) {
      if(!value) {
        return false;
      }
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.searchData = [];
      this.fetching = true;
      // 使用 request 函数从指定接口获取数据
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/findEnterpriseByName/'+value, 'get').then(res => {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return;
          }
          console.log(res,'res')
          this.searchData = res.data.data;
          this.fetching = false;
        })
        .catch(error => {
          console.error('Error fetching user:', error);
          // 处理错误
        });
    },
    handleGsChange(searchVal) {
      Object.assign(this, {
        searchVal,
        searchData: [],
        fetching: false,
      });
    },
    selectGs(data){
      this.searchVal.key = data.Name;
      this.details.name = data.Name;
      this.details.creditCode = data.CreditCode
      this.details.establishTime = data.StartDate
      this.details.representativeName = data.OperName
      this.details.customerStatus = data.Status
      this.details.registerNumber = data.KeyNo
      this.details.registerAddress = data.Address
    },
     // 添加预算
    addWelfare(item){
       // 找到被点击按钮的索引
       const index = this.details.customerWelfareList.indexOf(item);
       if (index > -1) {
        // 创建一个新对象，内容与传入的 item 相同，但标记为不需要展示添加按钮
        const newItem = Object.assign({}, item, { showAddButton: 1 }); // 或者使用扩展运算符：const newItem = { ...item, showAddButton: false };
        newItem.expectedProcurementMonth = 0
        newItem.welfareAmount = 0
        newItem.isDel = true
        delete newItem.id
            // 在索引后面插入新的元素
        this.details.customerWelfareList.splice(index + 1, 0, newItem);
      }
    },
    delWelfare(index) {
      this.details.customerWelfareList.splice(index,1);
    },  
    getCusDetails() {
      if (!this.selectId) return false;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/findById/' + this.selectId, 'get').then(res => {
        console.log(11111)
        this.details = res.data.data;
        this.invoiceData = {
          creditCode:this.details.creditCode,
          name:this.details.name,
        }
        this.searchVal.key = this.details.name;
        const welfareIdMap = {};
        // if(!this.details.customerWelfareList) {
        //   this.details.customerWelfareList = this.defaultWelfareList;
        // }
          this.details.customerWelfareList.forEach(item => {
            if (welfareIdMap[item.welfareId]) {
                item.isDel = true;
            } else {
                welfareIdMap[item.welfareId] = true;
            }
          });
        this.$emit('getSfaContactsList', res.data.data.sfaContactsList,this.details.name);
        this.isEdit = false;
        // 福利类型
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/welfare/list', 'get').then(res => {
          this.welfare = res.data.data;
          let arr = this.defaultWelfareList
          this.describeText = "";
          this.welfare.forEach(item => {
            this.describeText += item.describe + '+';
            arr.forEach(son => {
              if (item.id === son.welfareId) {
                son.describe = item.describe;
              }
            });
          });
          this.describeText = this.describeText.replace(/\+$/, '');
          this.$forceUpdate();
        });
        console.log(this.details,'this.details')
        if (!this.details.customerAddressList) {
            this.$set(this.details, 'customerAddressList', [{
              "provinceCode": '',
              "provinceName": "",
              "cityCode": '',
              "cityName": "",
              "countyCode": '',
              "countyName": "",
              "detailedAddress": ""
            }]);
          }
      })
    },
    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
      return result
    },
    back() {
      this.$router.replace('/sfa_customer/sfa_customer_list')
    },
    // 添加地址
    addAddress(type, i) {
      console.log(type, 'type')
      if (type == 3 || type == 4) {
        if (type == 3) {
          this.details.customerAddressList.push({})
        } else {
          this.details.customerAddressList.splice(i, 1)
        }
        return false;
      }
    },
    provChange(id, index) {
      const selProv = this.prov.find(item => item.id === id);
      this.details.customerAddressList[index].provinceCode = selProv.id;
      this.details.customerAddressList[index].provinceName = selProv.name;
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.city = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    cityChange(id, index) {
      const selProv = this.city.find(item => item.id === id);
      this.details.customerAddressList[index].cityCode = selProv.id;
      this.details.customerAddressList[index].cityName = selProv.name;
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.area = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    areaChange(id, index) {
      const selProv = this.area.find(item => item.id === id);
      this.details.customerAddressList[index].countyCode = selProv.id;
      this.details.customerAddressList[index].countyName = selProv.name;
    },
    getCity() {
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/0', 'get').then(res => {
        if (res.data.code == 200) {
          this.prov = res.data.data;
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    handleOk() {
      let fare = true;
      this.details.customerWelfareList.forEach((obj) => {
        // if(obj.expectedProcurementMonth) {
        //   obj.expectedProcurementMonth = new Date(obj.expectedProcurementMonth).getMonth() + 1;
        // }
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const value = obj[key];
            // 检查值是否为空
            if (!value && value !== 0) {
              fare = false;
            }
          }
        }
      });
      // 过滤空地址
      let customerAddressList = this.details.customerAddressList;
      customerAddressList = this.details.customerAddressList.map(obj => {
        for (let key in obj) {
          if (obj[key] === '') {
            delete obj[key];
          }
        }
        return obj;
      }).filter(obj => Object.values(obj).some(value => value !== ''));
      if (!fare) {
        this.$message.error('福利金额不可为空！');
        return false;
      }

      // let arr = this.details.customerAddressList;
      // this.details.customerAddressList = arr.filter(obj => Object.keys(obj).length > 0);
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          console.log(this.details,'this.details')
          let params = {
            "id": this.selectId,
            "name": this.details.name,
            "type": 1,
            "scale": this.details.scale,
            "enterpriseType": this.details.enterpriseType,
            "remarks": this.details.remarks,
            "abbreviation": this.details.abbreviation,
            "source": this.details.source,
            "budgetAmount": this.details.budgetAmount,
            "creditCode":this.details.creditCode,
            "establishTime":this.details.establishTime,
            "representativeName":this.details.representativeName,
            "customerStatus":this.details.customerStatus,
            "registerNumber":this.details.registerNumber,
            "registerAddress":this.details.registerAddress,
            "customerManagerIdList": this.details.customerManagerIdList,
            "customerWelfareList": this.details.customerWelfareList,
            // "customerAddressList": this.details.customerAddressList
            "customerAddressList": customerAddressList
          }
          console.log(params,'params')
          this.$message.loading();
          // 校验名称是否有重复
          request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/check', 'post', params).then(res => {
              if (res.data.data) {
                this.$message.warning(res.data.data)
                return false;
              }
              this.$message.loading();
              request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/update', 'post', params).then(res => {
                this.$message.destroy()
                if (res.data.code == 200) {
                  this.$message.success('更新成功！');
                  this.isEdit = false;
                  EventBus.$emit('itemRefresh');
                  this.getCusDetails();
                } else {
                  this.$message.error(res.data.message);
                }
              })
            })
        
        } else {
          return false;
        }
      });

    },
    handleSubmit(e) {
      console.log(1233212)
      e.preventDefault()
    },
    newMember() {
      this.dataSource.push({
        key: this.dataSource.length + 1,
        name: '',
        number: '',
        department: '',
        editable: true,
        isNew: true
      })
    },
    remove(key) {
      const newData = this.dataSource.filter(item => item.key !== key)
      this.dataSource = newData
    },
    saveRow(key) {
      let target = this.dataSource.filter(item => item.key === key)[0]
      target.editable = false
      target.isNew = false
    },
    toggle(key) {
      let target = this.dataSource.filter(item => item.key === key)[0]
      target.editable = !target.editable
    },
    getRowByKey(key, newData) {
      const data = this.dataSource
      return (newData || data).filter(item => item.key === key)[0]
    },
    cancle(key) {
      let target = this.dataSource.filter(item => item.key === key)[0]
      target.editable = false
    },
    handleChange(value, key, column) {
      const newData = [...this.dataSource]
      const target = newData.filter(item => key === item.key)[0]
      if (target) {
        target[column] = value
        this.dataSource = newData
      }
    }
  }
}
</script>

<style lang="less" scoped>
.card {
  margin-bottom: 24px;
}

::v-deep .ant-card-body {
  padding: 12px 24px;
}

::v-deep .ant-input[disabled] {
  background: #eee;
  color: #333;
}

::v-deep .ant-select-disabled .ant-select-selection {
  background: #eee;
  color: #333;
}

.isWidth1700 {
  display: none;
}

@media (max-width: 1700px) {
  .isWidth1700 {
    display: block;
  }

  .isWidthMax1700 {
    display: none;
  }
}

/* 在屏幕宽度大于 1700px 时应用的样式 */
@media (min-width: 1701px) {
  .isWidth1700 {
    display: none;
  }

  .isWidthMax1700 {
    display: block;
  }
}
/* 隐藏年份部分 */
.ant-picker-month-panel .ant-picker-month-panel-header {
  display: none;
}
</style>
